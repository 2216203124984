<template>
  <div>
    <!-- colar form funcionario aqui  -->

    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-10">
        <div class="card full-height bg-ligth">
          <div style="background: #ffa500" class="p-5 text-center">
            <h1 style="color: white; font-weight: 700">Historico Pontos Batidos</h1>
          </div>
          <div class="card-body">
            <div class="col-md-12">
              <!-- <div class="portlet light"> -->
              <!-- <h3>Cadastrar</h3> -->
              <hr />

              <!-- <form class="form">
                <div class="card-body">
                  <div class="form-group">
                    <label for="">Data Incial</label>
                    <input type="date" name=""v-model="form.data_inicial" id="">
                    <label for="">Data Final</label>
                    <input type="date" name=""v-model="form.data_inicial" id="">
                  </div>
                </div>
              </form> -->

              <table class="table table-head-bg-primary">
                <thead>
                  <tr>
                    <th scope="col">N</th>
                    <th class="text-center" scope="col">Nome</th>
                    <th class="text-center" scope="col">Registro</th>
                    <th class="text-center" scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lista, index) in lista_logs_pontos" :key="index">
                    <td class="text-left">
                      <p>
                        {{ lista.pis }}
                      </p>
                    </td>
                    <td class="text-center">
                      {{ lista.nome }} {{ lista.sobrenome }}
                    </td>
                         <td class="text-center">
                      {{ lista.registro_ponto }}
                    </td>

                    <td class="text-center">
                      <button
                        @click="atualizar(lista)"
                        class="btn btn-sm btn-icon btn-bg-primary btn-icon-light btn-hover-light"
                      >
                        <i class="flaticon-edit-1"></i>
                      </button>

                      <button
                        @click="confirm(lista)"
                        class="ml-1 btn btn-sm btn-icon btn-bg-danger btn-icon-light btn-hover-light"
                      >
                        <i class="flaticon-lock"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Controle Relogio" }]);
  },
  // created() {
  //   this.lista_matriculas();
  // },
  computed: {
    lista_logs_pontos() {
      return this.$store.state.configEmpresa.lista_registros;
    },
    //  lista_permissoes_filial(){
    //     return this.$store.state.sistema.lista_permissoes_filial;
    // }
  },

  created() {
    this.listar_logs_pontos();
  },
  methods: {
    async listar_logs_pontos() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("listar_registro_relogio").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({ name: "createEmpresa" });
      this.$store.dispatch("atualizar", value);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>